import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import { OpenOutlineIc } from '@dsch/dd-icons';

import { BaseBadge } from 'components/Toolkit/BaseBadge/BaseBadge';
import { Tag } from 'components/Toolkit/Tags/Tag';
import { theme } from 'helpers/Theme';
import { Link } from 'components/Toolkit/Button/Link';
import { media } from 'helpers/breakpoints';

export type MenuItemsType = Array<MenuItem | Array<MenuItem>>;

type Variant = 'DASHBOARD' | 'DEALERHUB';
export type MenuItem = {
  id?: string;
  displayName: string;
  link?: string;
  newTab?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  divider?: boolean;
  notifications?: number | null;
  selected?: boolean;
  variant?: Variant;
  newTabIcon?: boolean;
  betaIcon?: boolean;
  includeNextLink?: boolean;
  dataTracking?: string;
  itemHidden?: boolean;
};

export interface IMenuProps {
  items: MenuItemsType;
  icons?: boolean;
  value?: string;
  variant?: Variant;
  className?: string;
  selectedSection?: string;
}

const Container = styled.ul`
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: 0;
  position: relative;
`;

interface IItem {
  divider: boolean;
  selected: boolean;
  variant?: Variant;
}

export const Item = styled.li<IItem>`
  flex: 1 1 auto;
  ${({ divider, theme }) =>
    divider &&
    css`
      &:before {
        display: block;
        height: 1px;
        margin-top: ${({ theme }) => theme.spacing.S4};
        margin-bottom: ${({ theme }) => theme.spacing.S4};
        background: ${theme.colors.GREY_LIGHT};
        content: '';
      }
    `}

  &:hover {
    ${({ selected, theme }) =>
      !selected && `background-color: ${theme.colors.OFFWHITE};`}
  }
`;

const ImageContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing.S8};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
`;

interface StyledLinkStyledProps {
  variant?: Variant;
  selected?: boolean;
}
export const StyledLink = styled(Link)<StyledLinkStyledProps>`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  height: 100%;
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeight.bold : theme.fontWeight.regular};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
    border-radius: ${({ variant }) => variant === 'DASHBOARD' && '2px'};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    text-decoration: none;
  }

  ${media.medium} {
    ${({ theme }) => theme.fontSize.M14};
  }
`;

const NestedContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > span {
    flex: 0 0 auto;
  }

  ${Item} {
    width: 100%;
  }

  ${Item}:first-child {
    ${StyledLink} {
      padding-right: 0;
    }
  }

  ${Item}:not(:first-child) {
    ${StyledLink} {
      padding-left: 6px;
    }
  }
`;

const NestedItem = styled.li`
  width: 100%;
`;

export const StyledBadge = styled(BaseBadge)`
  margin: auto 0 auto ${({ theme }) => theme.spacing.S2};
  display: flex;
`;

const Slider = styled.span<{ index: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  border: ${({ theme }) => `2px solid ${theme.colors.GREY_DARKER}`};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: pointer;
  pointer-events: none;

  transform: translateY(calc(${({ index }) => index * 48}px));
  transition: all 0.1s ease-in-out;
`;

const SOpenOutlineIc = styled(OpenOutlineIc)`
  margin-left: ${({ theme }) => theme.spacing.S8};
`;

const STag = styled(Tag)`
  margin-left: ${({ theme }) => theme.spacing.S8};
`;

function MenuItem({
  item,
  icons,
  variant,
}: {
  item: MenuItem;
  icons: boolean;
  variant?: Variant;
}) {
  return (
    <Item
      as="div"
      divider={item.divider || false}
      selected={!!item.selected}
      data-testid={item.selected ? 'menu-item-selected' : 'menu-item'}
      variant={variant}
    >
      <StyledLink
        variant={variant}
        NextLink={item.includeNextLink ? NextLink : undefined}
        href={item.link}
        isFakeLink={!Boolean(item.link)}
        inline={!Boolean(item.link)}
        ofType="SECONDARY"
        onClick={item.onClick}
        selected={!!item.selected}
        data-tracking={item.dataTracking}
        data-testid={`menu-item-${item.displayName
          .replace(/\s+/g, '-')
          .toLowerCase()}`}
        shallow={item.includeNextLink}
      >
        {icons && item.icon && <ImageContainer>{item.icon}</ImageContainer>}
        {item.displayName}
        {item.notifications && <StyledBadge count={item.notifications} />}
        {item.newTabIcon && (
          <SOpenOutlineIc
            color={theme.colors.BLUE_DARK}
            width="12"
            height="12"
          />
        )}
        {item.betaIcon && (
          <STag text="Beta" textColor="GREY_DARK" bgColor="GREY_LIGHTER" />
        )}
      </StyledLink>
    </Item>
  );
}

function Menu({
  items,
  icons = false,
  variant,
  className,
  selectedSection,
}: IMenuProps) {
  const selectedIndex = items.findIndex((tab) => {
    if (Array.isArray(tab)) {
      return tab[0].id === selectedSection;
    } else return tab.id === selectedSection;
  });

  return (
    <Container className={className}>
      {items.map((item, index) => {
        if (Array.isArray(item)) {
          return (
            <NestedItem key={`item-${index}`}>
              <NestedContainer>
                {item.map((item, index) => (
                  <>
                    {index !== 0 && <span>/</span>}
                    <MenuItem
                      item={item}
                      icons={icons}
                      key={`nested-${index}`}
                    />
                  </>
                ))}
              </NestedContainer>
            </NestedItem>
          );
        }
        return (
          <MenuItem item={item} icons={icons} key={index} variant={variant} />
        );
      })}
      {typeof selectedSection !== 'undefined' &&
      (variant === 'DASHBOARD' || variant === 'DEALERHUB') ? (
        <Slider index={selectedIndex} />
      ) : undefined}
    </Container>
  );
}

export { Menu };
